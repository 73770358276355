import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Icon from 'components/icon/icon.component';
import Button from 'components/button/button.component';
import styles from './basic.module.scss';

const Sidebar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const routes = ['user-groups', 'users'];

  return (
    <div className={styles.basicLayout__sidebar}>
      <nav className={styles.basicLayout__menu}>
        <h1 className={styles.basicLayout__menu__title}>
          <img src="/logo192.png" alt="logo" />
          <span>Commonark</span>
        </h1>
        <ul>
          {routes.map((route) => (
            <li key={route} className={location.pathname.includes(route) ? styles.active : ''}>
              <Link to={`/${route}`}>{t(`navigation.${route}`)}</Link>
            </li>
          ))}
        </ul>
      </nav>
      <Button onClick={() => navigate('/sign-in')} secondary>
        {t('navigation.logOut')}
        <Icon name={'LogOut'} size={18} />
      </Button>
    </div>
  );
};

export default Sidebar;
