import React, {
  FormEventHandler,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { format } from 'date-fns';
import styles from '../users.module.scss';

import { getUserGroups } from 'requests/users.request';
import { IDictionary, IRange } from 'interfaces/shared.interface';
import { IUserFilter, IUserGroup } from 'interfaces/users.interface';
import { ROLES_DICT, YES_NO_DICT } from 'constants/users.constant';
import Input from 'components/input/input.component';
import Select from 'components/select/select.component';
import RangePicker from 'components/date-picker/range-picker.component';
import Button from 'components/button/button.component';
import { removeEmptyStrings } from 'utils/form.utils';
import { isEmpty } from 'utils/shared.utils';

interface IProps {
  handleSubmit: FormEventHandler<HTMLFormElement>;
  loading: boolean;
}

const UsersFilter = forwardRef(function UsersFilter({ handleSubmit, loading }: IProps, ref) {
  const [filter, setFilter] = useState<IUserFilter>({
    username: '',
    role: undefined,
    isAgentInstalled: '',
    updateDateFrom: '',
    updateDateTo: '',
    groupName: '',
  });
  const [updateDate, setUpdateDate] = useState<IRange>();
  const [userGroups, setUserGroups] = useState<IUserGroup[]>([]);

  const handleGetUserGroups = () => {
    const params = {
      page: 0,
      size: 999,
    };
    getUserGroups(params)
      .then((res) => setUserGroups(res.content))
      .catch(() => null);
  };

  useEffect(() => {
    handleGetUserGroups();
  }, []);

  const handleChangeFilter = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFilter((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeDate = (date: [Date | null, Date | null]) => {
    setUpdateDate(date);
    setFilter((prevState) => ({
      ...prevState,
      updateDateFrom: date[0] ? format(date[0], 'yyyy-MM-dd 00:00:00') : undefined,
      updateDateTo: date[1] ? format(date[1], 'yyyy-MM-dd 23:59:59') : undefined,
    }));
  };

  useImperativeHandle(
    ref,
    () => {
      const parsedFilter = {
        ...filter,
        isAgentInstalled: !isEmpty(filter.isAgentInstalled)
          ? !!+(filter.isAgentInstalled as string)
          : '',
      };
      return {
        filter: removeEmptyStrings(parsedFilter),
      };
    },
    [filter],
  );

  return (
    <form className={styles.users__filter} onSubmit={handleSubmit}>
      <Input
        name="username"
        value={filter.username}
        label={'Имя пользователя'}
        onChange={handleChangeFilter}
        disabled={loading}
      />
      <Select<IDictionary>
        name="role"
        options={ROLES_DICT}
        value={filter.role}
        label={'Роль'}
        valueKey={'value'}
        labelKey={'name'}
        onChange={handleChangeFilter}
        disabled={loading}
      />
      <Select<IDictionary>
        name="isAgentInstalled"
        options={YES_NO_DICT}
        value={filter.isAgentInstalled}
        label={'Агент установлен'}
        valueKey={'value'}
        labelKey={'name'}
        onChange={handleChangeFilter}
        disabled={loading}
      />
      <Select<IUserGroup>
        name="groupName"
        options={userGroups}
        value={filter.groupName}
        label={'Наименование группы'}
        valueKey={'groupName'}
        labelKey={'groupName'}
        onChange={handleChangeFilter}
        disabled={loading}
      />
      <RangePicker
        name="updateDate"
        value={updateDate}
        label={'Последнее время обновления'}
        onChange={handleChangeDate}
        disabled={loading}
      ></RangePicker>
      <Button type="submit" primary>
        Найти
      </Button>
    </form>
  );
});

export default UsersFilter;
