import React from 'react';
import { clsx } from 'clsx';
import styles from './button.module.scss';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  type?: 'submit' | 'reset' | 'button';
  children?: React.ReactNode;
  primary?: boolean;
  secondary?: boolean;
}

const Button: React.FC<IProps> = ({ type = 'button', children, primary, secondary, ...rest }) => {
  return (
    <button
      type={type}
      className={clsx({
        [styles.btn]: true,
        [styles.btn__primary]: primary,
        [styles.btn__secondary]: secondary,
      })}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
