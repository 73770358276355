import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './users.module.scss';

import { IUser, IUserFilter } from 'interfaces/users.interface';
import { useModal } from 'contexts/modal.context';
import { makeUserAdmin, unmakeUserAdmin, getUsers, syncUsers } from 'requests/users.request';
import { USER_COLUMNS } from 'constants/users.constant';
import Pagination from 'components/pagination/pagination.component';
import Loader from 'components/loader/loader.component';
import Icon from 'components/icon/icon.component';
import Button from 'components/button/button.component';
import Table from 'components/table/table.component';
import AddUsersToGroupModal from './components/add-users-to-group.modal';
import UsersFilter from './components/users-filter.component';
import { clsx } from 'clsx';

const Users = () => {
  const itemsPerPage = 10;
  const { t } = useTranslation();
  const modal = useModal();
  const filterRef = useRef<{ filter: IUserFilter }>();

  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const handleGetUsers = () => {
    setLoading(true);
    const filter = filterRef.current?.filter ?? {};
    const params = {
      page,
      size: itemsPerPage,
      ...filter,
    };
    getUsers(params)
      .then((res) => {
        setLoading(false);
        setUsers(res.content);
        setSelectAll(res.content.length > 0 && res.content.every((user) => isSelected(user)));
        setTotalItems(res.totalElements);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    handleGetUsers();
  }, [page]);

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleChangeUserRole = (user: IUser) => {
    setLoading(true);
    const promise = user.role === 'ROLE_USER' ? makeUserAdmin(user.id) : unmakeUserAdmin(user.id);
    promise
      .then(() => {
        const role = user.role === 'ROLE_USER' ? 'ROLE_ADMIN' : 'ROLE_USER';
        setUsers((prevState) =>
          prevState.map((item) => (item.id === user.id ? { ...item, role } : item)),
        );
      })
      .catch(() => setLoading(false))
      .finally(() => setLoading(false));
  };

  const handleGetTableValue = (item: IUser, key: string) => {
    switch (key) {
      case 'role':
        return t(`enum.role.${item.role}`);
      case 'agentInstalled':
        return (
          <div className="text-center">
            {item.agentInstalled ? t('enum.yesNo.yes') : t(`enum.yesNo.no`)}
          </div>
        );
      case 'userGroup':
        return item.userGroup?.groupName;
      case 'actions':
        return (
          <button
            type="button"
            className={clsx({
              commonArk__iconBtn: true,
              [styles['users__tableActions__button--makeAdmin']]: item.role === 'ROLE_USER',
              [styles['users__tableActions__button--withdrawAdmin']]: item.role === 'ROLE_ADMIN',
            })}
            onClick={() => handleChangeUserRole(item)}
            data-tooltip={
              item.role === 'ROLE_USER'
                ? 'Назначить администратором'
                : 'Отозвать роль администратора'
            }
            data-tooltip-position={'left'}
          >
            <Icon name={item.role === 'ROLE_USER' ? 'UserCheck' : 'UserX'} size={18} />
          </button>
        );
      default: {
        return item[key as keyof IUser];
      }
    }
  };

  const handleSyncUsers = () => {
    setLoading(true);
    syncUsers()
      .then(() => handleGetUsers())
      .catch(() => setLoading(false))
      .finally(() => setLoading(false));
  };

  const isSelected = (user: IUser) => {
    return selectedUserIds.includes(user.id) || selectAll;
  };

  const handleSelectUser = (user: IUser) => {
    setSelectedUserIds((prevSelected) =>
      prevSelected.includes(user.id)
        ? prevSelected.filter((id) => id !== user.id)
        : [...prevSelected, user.id],
    );
  };

  const handleOpenAddToGroupModal = () => {
    modal.open(
      <AddUsersToGroupModal
        userIds={selectedUserIds}
        isSelectedAll={selectAll}
        filter={filterRef.current?.filter}
      />,
      {
        title: t('users.addToGroup'),
        width: 350,
        handleOk: () => {
          modal.close();
          setSelectedUserIds([]);
          handleGetUsers();
        },
      },
    );
  };

  const handleSubmitFilter = async (e: React.FormEvent) => {
    e.preventDefault();
    if (page === 0) {
      handleGetUsers();
    } else {
      setPage(0);
    }
  };

  useEffect(() => {
    if (!selectAll) {
      setSelectedUserIds([]);
    }
  }, [selectAll]);

  return (
    <Loader loading={loading}>
      <div className={clsx({ [styles.users]: true, commonArk__wrapper: true })}>
        <div className={styles.users__header}>
          <div className="commonArk__header">
            <h1 className="commonArk__title">{t('navigation.users')}</h1>
            <button
              type="button"
              className="commonArk__iconBtn"
              title={'Синхронизировать с АД'}
              onClick={handleSyncUsers}
            >
              <Icon name={'RefreshCcw'} stroke={'#2196f3'} />
            </button>
          </div>
          {(selectAll || selectedUserIds.length > 0) && (
            <Button primary onClick={handleOpenAddToGroupModal}>
              Добавить в группу
            </Button>
          )}
        </div>
        <UsersFilter ref={filterRef} handleSubmit={handleSubmitFilter} loading={loading} />
        <Table<IUser>
          columns={USER_COLUMNS}
          data={users}
          translateKey={'users'}
          isSelected={isSelected}
          handleSelect={handleSelectUser}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          handleGetTableValue={handleGetTableValue}
        />
        <Pagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={page}
          onPageChange={handlePageChange}
          centered={true}
        />
      </div>
    </Loader>
  );
};

export default Users;
