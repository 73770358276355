import React, { useMemo, useState } from 'react';
import { DefaultTFuncReturn } from 'i18next';
import { FormikProps } from 'formik';
import DatePicker, { registerLocale } from 'react-datepicker';
import { clsx } from 'clsx';
import { ru } from 'date-fns/locale';

import Icon from 'components/icon/icon.component';
import { isEmpty } from 'utils/shared.utils';
import 'react-datepicker/dist/react-datepicker.css';
import { IRange } from 'interfaces/shared.interface';
import styles from './date-picker.module.scss';

interface IProps {
  name: string;
  value: IRange | undefined;
  onChange: (date: [Date | null, Date | null]) => void;
  formik?: FormikProps<any>;
  label?: DefaultTFuncReturn | string;
  disabled?: boolean;
}

registerLocale('ru', ru);

const RangePicker: React.FC<IProps> = ({ formik, name, label, value, onChange, disabled }) => {
  const [isFocused, setIsFocused] = useState(false);
  const getError: string | null = useMemo(() => {
    if (!formik?.errors) {
      return null;
    }

    return formik?.errors[name] as string;
  }, [formik, name]);

  return (
    <div
      className={clsx({
        [styles.datePicker]: true,
        [styles['datePicker--error']]: !!getError && formik?.touched[name],
        [styles['datePicker--success']]: !getError && formik?.touched[name],
        [styles['datePicker--focused']]: isFocused,
        [styles['datePicker--haveValue']]:
          !isEmpty(value && value[0]) || !isEmpty(value && value[1]),
      })}
    >
      <DatePicker
        name={name}
        selected={value && value[0]}
        onChange={onChange}
        startDate={value && (value[0] as Date | undefined)}
        endDate={value && (value[1] as Date | undefined)}
        selectsRange
        locale="ru"
        dateFormat="dd.MM.yyyy"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        disabled={disabled}
      />
      {label ? <label>{label}</label> : null}
      {formik?.touched[name] && !!getError && (
        <span className={styles['input--error__message']} aria-label={getError}>
          <Icon name={'CircleAlert'} size={16} />
        </span>
      )}
      {(!isEmpty(value && value[0]) || !isEmpty(value && value[1])) && (
        <button
          type="button"
          className={styles.datePicker__clear}
          onClick={() => onChange([null, null])}
        >
          <Icon name={'X'} size={16} />
        </button>
      )}
    </div>
  );
};

export default RangePicker;
