import api from './api';
import { IPageableResponse } from 'interfaces/shared.interface';
import {
  IAllUsersToGroup,
  IUser,
  IUserGroup,
  IUserParams,
  UserRole,
} from 'interfaces/users.interface';

const _resourceUrl = '/front';

export const getUsers = (params: IUserParams): Promise<IPageableResponse<IUser>> => {
  return api.get(`${_resourceUrl}/get-users`, { params });
};

export const getUserGroups = (
  params: Record<string, number>,
): Promise<IPageableResponse<IUserGroup>> => {
  return api.get(`${_resourceUrl}/get-user-groups`, { params });
};

export const saveUserGroup = (userGroup: {
  userGroupId: number | null;
  groupName: string;
}): Promise<IUserGroup> => {
  return api.post(`${_resourceUrl}/save-user-group`, userGroup);
};

export const removeUserGroup = (userGroup: {
  userGroupId: number | null;
  groupName: string;
}): Promise<IUserGroup> => {
  return api.post(`${_resourceUrl}/remove-user-group`, userGroup);
};

export const addSelectedUsersToGroup = (dto: {
  userGroupId?: number;
  userIds: number[];
}): Promise<string> => {
  return api.post(`${_resourceUrl}/add-users-to-group`, dto);
};

export const addAllUsersToGroup = (filter: IAllUsersToGroup): Promise<string> => {
  return api.post(`${_resourceUrl}/add-all-users-to-group`, filter);
};

export const syncUsers = (): Promise<string> => {
  return api.post(`${_resourceUrl}/sync-ldap-users`);
};

export const makeUserAdmin = (userId: number): Promise<boolean> => {
  return api.post(`${_resourceUrl}/make-user-admin`, { userId });
};

export const unmakeUserAdmin = (userId: number): Promise<boolean> => {
  return api.post(`${_resourceUrl}/unmake-user-admin`, { userId });
};
