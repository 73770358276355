import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import BasicLayout from 'layouts/basic/basic.layout';
import SignIn from 'pages/sign-in/sign-in.page';
import Settings from 'pages/settings/settings.page';
import Users from 'pages/users/users.page';
import UserGroups from 'pages/user-groups/user-groups.page';

export default function RoutesIndex() {
  return (
    <Routes>
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/" element={<BasicLayout />}>
        <Route index element={<Navigate to="/user-groups" replace />} />
        <Route path="/users" element={<Users />} />
        <Route path="/user-groups" element={<UserGroups />} />
        <Route path="/settings/:userGroupId" element={<Settings />} />
      </Route>
    </Routes>
  );
}
