import React from 'react';
import { clsx } from 'clsx';
import styles from './loader.module.scss';

interface IProps {
  loading: boolean;
  children?: React.ReactNode;
}

const Loader: React.FC<IProps> = ({ loading, children }) => {
  return (
    <div className={styles.loader__wrapper}>
      {loading && (
        <div className={styles.loader__nested}>
          <div className={styles.loader__spinner}></div>
          <div className={styles.loader__text}>Загрузка...</div>
        </div>
      )}
      <div
        className={clsx({
          [styles.loader__container]: true,
          [styles['loader__container--blur']]: loading,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Loader;
