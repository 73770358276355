import React from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'pages/auth-guard';
import Sidebar from 'layouts/basic/sidebar.component';
import styles from './basic.module.scss';

const BasicLayout = () => {
  return (
    <AuthGuard>
      <>
        <Sidebar />
        <div className={styles.basicLayout__wrapper}>
          <Outlet />
        </div>
      </>
    </AuthGuard>
  );
};

export default BasicLayout;
