import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '../checkbox/checkbox.component';
import { IColumn } from '../../interfaces/shared.interface';
import styles from './table.module.scss';

interface IProps<T> {
  columns: IColumn[];
  data: T[];
  translateKey: string;
  handleGetTableValue?: (item: T, key: string) => any;
  selectAll?: boolean;
  setSelectAll?: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelect?: (item: T) => void;
  isSelected?: (item: T) => boolean;
}

const Table = <T,>({
  columns,
  data,
  translateKey,
  handleGetTableValue,
  selectAll,
  setSelectAll,
  handleSelect,
  isSelected,
}: IProps<T>) => {
  const { t } = useTranslation();

  const _handleSelectAll = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAll && setSelectAll(checked);
  };

  const Columns = useMemo(() => {
    return columns.map((column) => (
      <th key={column.key} style={{ width: column.width && `${column.width}px` }}>
        {column.key !== 'actions' && t(`${translateKey}.${column.key}`)}
      </th>
    ));
  }, [columns, translateKey]);

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          {(setSelectAll || handleSelect) && (
            <th style={{ width: '50px' }}>
              {setSelectAll && (
                <div>
                  <Checkbox name="selectAll" checked={selectAll} onChange={_handleSelectAll} />
                </div>
              )}
            </th>
          )}
          {Columns}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={`tableRow_${index}`}>
            {handleSelect && isSelected && (
              <td>
                <Checkbox
                  name={`${index}`}
                  checked={isSelected(item)}
                  onChange={() => handleSelect(item)}
                />
              </td>
            )}
            {columns.map((column) => (
              <td key={`${column.key}_${index}`}>
                {handleGetTableValue
                  ? handleGetTableValue(item, column.key)
                  : item[column.key as keyof T]}
              </td>
            ))}
          </tr>
        ))}
        {!data?.length && (
          <tr>
            <td colSpan={columns.length + 1} style={{ textAlign: 'center' }}>
              Нет данных
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Table;
