import { IColumn, IDictionary } from '../interfaces/shared.interface';

export const ROLES_DICT: IDictionary[] = [
  { value: 'ROLE_ADMIN', name: 'Администратор' },
  { value: 'ROLE_USER', name: 'Пользователь' },
];

export const YES_NO_DICT: IDictionary[] = [
  { value: 0, name: 'Нет' },
  { value: 1, name: 'Да' },
];

export const USER_COLUMNS: IColumn[] = [
  { key: 'username' },
  { key: 'role' },
  { key: 'lastCheckInDate' },
  { key: 'userGroup' },
  { key: 'agentInstalled', width: 170 },
  { key: 'actions', width: 50 },
];

export const USER__GROUP_COLUMNS: IColumn[] = [
  { key: 'id', width: 125 },
  { key: 'groupName' },
  { key: 'global' },
  { key: 'actions', width: 125 },
];
