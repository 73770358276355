import React, { useState } from 'react';
import { IModalOptions } from 'interfaces/modal.interface';
import Input from '../../components/input/input.component';
import { IUserGroup } from '../../interfaces/users.interface';
import Button from '../../components/button/button.component';
import { useModal } from '../../contexts/modal.context';
import { saveUserGroup } from '../../requests/users.request';
import { toast } from 'react-toastify';

interface IProps extends IModalOptions {
  userGroup?: IUserGroup;
}

const CreateUserGroupModal: React.FC<IProps> = ({ userGroup, handleOk }) => {
  const { close } = useModal();
  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<IUserGroup>(
    userGroup ?? {
      id: 0,
      groupName: '',
    },
  );

  const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      userGroupId: form.id ? form.id : null,
      groupName: form.groupName,
    };
    saveUserGroup(data)
      .then(() => {
        toast.success(`Группа успешно ${userGroup?.id ? 'сохранена' : 'создана'}`);
        handleOk && handleOk();
      })
      .catch(() => toast.error('Произошла ошибка'))
      .finally(() => setLoading(false));
  };

  return (
    <form className="df fd-c" onSubmit={handleSubmit}>
      <Input
        name="groupName"
        value={form.groupName}
        label={'Наименование группы'}
        onChange={handleChange}
        disabled={loading}
      />
      <div className="modal__footer">
        <Button secondary onClick={close}>
          Отмена
        </Button>
        <Button type="submit" primary disabled={!form.groupName || loading}>
          {userGroup?.id ? 'Сохранить' : 'Создать'}
        </Button>
      </div>
    </form>
  );
};

export default CreateUserGroupModal;
