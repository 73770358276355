const _FONTS = [
  'Arial',
  'Calibri',
  'Times New Roman',
  'Verdana',
  'Tahoma',
  'Georgia',
  'Courier New',
  'Trebuchet MS',
  'Comic Sans MS',
  'Lucida Console',
];
const _FONTS_SIZES = [4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 28, 32, 48, 64, 72];

export const FONTS = _FONTS.map((font) => ({ id: font, name: font }));
export const FONTS_SIZES = _FONTS_SIZES.map((size) => ({ id: size, name: size }));
