import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { IModalOptions } from 'interfaces/modal.interface';
import { IUserFilter, IUserGroup } from 'interfaces/users.interface';
import { useModal } from 'contexts/modal.context';
import { addAllUsersToGroup, addSelectedUsersToGroup, getUserGroups } from 'requests/users.request';
import Button from 'components/button/button.component';
import Select from 'components/select/select.component';

interface IProps extends IModalOptions {
  userIds: number[];
  isSelectedAll: boolean;
  filter?: IUserFilter;
}

const AddUsersToGroupModal: React.FC<IProps> = ({ userIds, isSelectedAll, handleOk, filter }) => {
  const { close } = useModal();
  const [loading, setLoading] = useState<boolean>(false);
  const [userGroupId, setUserGroupId] = useState<number>();
  const [userGroups, setUserGroups] = useState<IUserGroup[]>([]);

  const handleGetUserGroups = () => {
    setLoading(true);
    const params = {
      page: 0,
      size: 999,
    };
    getUserGroups(params)
      .then((res) => {
        setLoading(false);
        setUserGroups(res.content);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    handleGetUserGroups();
  }, []);

  const handleChange = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    setUserGroupId(+target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    if (isSelectedAll) {
      handleAddAllUsers();
    } else {
      handleAddSelectedUsers();
    }
  };

  const responseSubscribe = (addUsersRequest: Promise<string>) => {
    addUsersRequest
      .then(() => {
        const group = userGroups.find((item) => item.id === userGroupId);
        toast.success(`Пользователи успешно добавлены в группу ${group?.groupName ?? ''}`);
        handleOk && handleOk();
      })
      .catch(() => toast.error('Произошла ошибка'))
      .finally(() => setLoading(false));
  };

  const handleAddAllUsers = () => {
    const dto = filter ?? {};
    responseSubscribe(addAllUsersToGroup({ ...dto, userGroupId: userGroupId as number }));
  };

  const handleAddSelectedUsers = () => {
    const data = {
      userIds,
      userGroupId,
    };
    responseSubscribe(addSelectedUsersToGroup(data));
  };

  return (
    <form className="df fd-c" onSubmit={handleSubmit}>
      <Select<IUserGroup>
        name="groupName"
        options={userGroups}
        value={userGroupId}
        label={'Наименование группы'}
        labelKey={'groupName'}
        onChange={handleChange}
        disabled={loading}
      />
      <div className="modal__footer">
        <Button secondary onClick={close}>
          Отмена
        </Button>
        <Button type="submit" primary disabled={!userGroupId || loading}>
          Добавить
        </Button>
      </div>
    </form>
  );
};

export default AddUsersToGroupModal;
