import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { clsx } from 'clsx';
import { toast } from 'react-toastify';

import { useModal } from 'contexts/modal.context';
import { IUserGroup } from 'interfaces/users.interface';
import { getUserGroups, removeUserGroup } from 'requests/users.request';
import { USER__GROUP_COLUMNS } from 'constants/users.constant';
import Pagination from 'components/pagination/pagination.component';
import Loader from 'components/loader/loader.component';
import Icon from 'components/icon/icon.component';
import Table from 'components/table/table.component';
import ConfirmModal from 'components/confirm/confirm.modal';
import CreateUserGroupModal from './create-user-group.modal';
import styles from './user-groups.module.scss';

const itemsPerPage = 10;

const UserGroups = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const modal = useModal();

  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [userGroups, setUserGroups] = useState<IUserGroup[]>([]);

  const handleGetUserGroups = () => {
    setLoading(true);
    const params = {
      page,
      size: itemsPerPage,
    };
    getUserGroups(params)
      .then((res) => {
        setLoading(false);
        setUserGroups(res.content);
        setTotalItems(res.totalElements);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    handleGetUserGroups();
  }, [page]);

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleGetTableValue = (item: IUserGroup, key: string) => {
    switch (key) {
      case 'global':
        return item.global ? t('enum.yesNo.yes') : t(`enum.yesNo.no`);
      case 'actions':
        return (
          <div className="df" style={{ gap: '1rem' }}>
            <button
              type="button"
              className={clsx({
                commonArk__iconBtn: true,
                [styles['userGroups__action--editSettings']]: true,
              })}
              data-tooltip={'Редактировать настройки'}
              data-tooltip-position={'left'}
              onClick={() => navigate(`/settings/${item.id}`)}
            >
              <Icon name={'Settings'} size={18} />
            </button>
            <button
              type="button"
              className={clsx({
                commonArk__iconBtn: true,
                [styles['userGroups__action--editGroup']]: true,
              })}
              data-tooltip={'Редактировать группу'}
              data-tooltip-position={'left'}
              onClick={() => handleOpenCreateModal(item)}
            >
              <Icon name={'Pencil'} size={18} />
            </button>
            <button
              type="button"
              className={clsx({
                commonArk__iconBtn: true,
                [styles['userGroups__action--removeGroup']]: true,
              })}
              data-tooltip={'Удалить группу'}
              data-tooltip-position={'left'}
              onClick={() => handleRemoveUserGroup(item)}
            >
              <Icon name={'Trash'} size={18} />
            </button>
          </div>
        );
      default: {
        return item[key as keyof IUserGroup];
      }
    }
  };

  const handleOpenCreateModal = (userGroup?: IUserGroup) => {
    modal.open(<CreateUserGroupModal userGroup={userGroup} />, {
      title: t(`userGroups.${userGroup ? 'edit' : 'create'}`),
      width: 350,
      handleOk: () => {
        modal.close();
        if (page === 0) {
          handleGetUserGroups();
        } else {
          setPage(0);
        }
      },
    });
  };

  const handleRemoveUserGroup = (userGroup: IUserGroup) => {
    modal.open(<ConfirmModal description={t('userGroups.removeConfirm')} />, {
      title: t('userGroups.remove'),
      width: 350,
      handleOk: () => {
        modal.close();
        setLoading(true);
        const data = {
          userGroupId: userGroup.id,
          groupName: userGroup.groupName,
        };
        removeUserGroup(data)
          .then(() => {
            if (page !== 0 && userGroups.length === 1) {
              setPage(0);
            } else {
              handleGetUserGroups();
            }
          })
          .catch(() => {
            toast.error('Произошла ошибка при удалении группы');
            setLoading(false);
          });
      },
    });
  };

  return (
    <Loader loading={loading}>
      <div
        className={clsx({
          [styles.userGroups]: true,
          commonArk__wrapper: true,
        })}
      >
        <div className="commonArk__header">
          <h1 className="commonArk__title">{t('navigation.user-groups')}</h1>
          <button
            type="button"
            className="commonArk__iconBtn"
            title={'Создать группу'}
            onClick={() => handleOpenCreateModal()}
          >
            <Icon name={'CirclePlus'} stroke={'#4caf50'} fill={'#FFFFFF'} />
          </button>
        </div>

        <Table<IUserGroup>
          columns={USER__GROUP_COLUMNS}
          data={userGroups}
          translateKey={'userGroups'}
          handleGetTableValue={handleGetTableValue}
        />
        <Pagination
          className={styles.userGroups__pagination}
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={page}
          onPageChange={handlePageChange}
          centered={true}
        />
      </div>
    </Loader>
  );
};

export default UserGroups;
