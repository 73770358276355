import React, { useId, InputHTMLAttributes } from 'react';
import Icon from '../icon/icon.component';
import styles from './checkbox.module.scss';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const Checkbox = ({ label, ...rest }: IInputProps) => {
  const id = rest.id ?? useId();
  return (
    <div className={styles.checkbox__wrapper}>
      <input type="checkbox" id={id} className={styles.checkbox__input} {...rest} />
      <label className={styles.checkbox__label} htmlFor={id}>
        <span className={styles.checkbox__icon}>
          <Icon name="Check" size={18} />
        </span>
        {label && <span className={styles.checkbox__text}>{label}</span>}
      </label>
    </div>
  );
};
