import React from 'react';
import { IModalOptions } from 'interfaces/modal.interface';
import { useModal } from 'contexts/modal.context';
import Button from 'components/button/button.component';

interface IProps extends IModalOptions {
  description: string;
}

const ConfirmModal: React.FC<IProps> = ({ description, handleOk }) => {
  const { close } = useModal();

  return (
    <div className="df fd-c">
      <div>{description}</div>
      <div className="modal__footer">
        <Button secondary onClick={close}>
          Нет
        </Button>
        <Button primary onClick={() => handleOk && handleOk()}>
          Да
        </Button>
      </div>
    </div>
  );
};

export default ConfirmModal;
