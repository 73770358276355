import api from './api';
import { ISignInDto, ITokenResponse } from 'interfaces/auth.interface';

export const signIn = (data: ISignInDto): Promise<ITokenResponse> => {
  return api.post('auth/sign-in', data);
};

export const uploadLicense = (file: File): Promise<string> => {
  const formData = new FormData();
  formData.append('file', file);
  return api.post('license/upload', formData);
};
