import api from './api';
import { ISettings } from '../interfaces/settings.interface';

const _resourceUrl = '/front';

export const getSettings = (userGroupId: number): Promise<ISettings> => {
  return api.get(`${_resourceUrl}/get-settings`, { params: { userGroupId } });
};

export const saveSettings = (userGroupId: number, settings: ISettings): Promise<ISettings> => {
  return api.post(`${_resourceUrl}/save-settings`, {
    userGroupId,
    settings,
  });
};
