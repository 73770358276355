import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RgbaStringColorPicker } from 'react-colorful';
import { toast } from 'react-toastify';
import { clsx } from 'clsx';
import styles from './settings.module.scss';

import { getSettings, saveSettings } from 'requests/settings.request';
import { ISettings } from 'interfaces/settings.interface';
import { FONTS, FONTS_SIZES } from 'constants/settings.constant';
import Loader from 'components/loader/loader.component';
import Select from 'components/select/select.component';
import Input from 'components/input/input.component';
import Popover from 'components/popover/popover.component';
import Button from 'components/button/button.component';

const Settings = () => {
  const params = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [textValue, setTextValue] = useState<string>('Lorem ipsum');
  const [settings, setSettings] = useState<ISettings>({});

  const handleGetSettings = () => {
    const userGroupId = +(params.userGroupId as string);
    getSettings(userGroupId)
      .then((res) => setSettings(res))
      .catch(() => null)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (params.userGroupId) {
      handleGetSettings();
    }
  }, [params.userGroupId]);

  const handleChangeSettings =
    (key: keyof ISettings) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const numeric = ['fontSize', 'verticalSpacing', 'horizontalSpacing'];
      const value = numeric.includes(key) ? +e.target.value : e.target.value;
      setSettings((prevState) => ({ ...prevState, [key]: value }));
    };

  const handleColorChange = (rgbaColor: string) => {
    setSettings((prevState) => ({ ...prevState, fontColor: rgbaColor }));
  };

  const handleSaveSettings = () => {
    setLoading(true);
    const userGroupId = +(params.userGroupId as string);
    saveSettings(userGroupId, settings)
      .then(() => toast.success('Настройки успешно сохранены'))
      .catch(() => toast.error('Ошибка при сохранении'))
      .finally(() => setLoading(false));
  };

  return (
    <Loader loading={loading}>
      <div
        className={clsx({
          [styles.settings]: true,
          commonArk__wrapper: true,
        })}
      >
        <div className="commonArk__header">
          <h1 className="commonArk__title">{t('settings.title')}</h1>
          <Button primary onClick={handleSaveSettings}>
            Сохранить настройки
          </Button>
        </div>
        <div className={styles.settings__wrapper}>
          <div className={styles.settings__toolbar}>
            <Input
              name={'textValue'}
              value={textValue}
              onChange={({ target }) => setTextValue(target.value)}
              label="Текст"
            />
            <Select
              name={'fonts'}
              options={FONTS}
              value={settings.fontName}
              onChange={handleChangeSettings('fontName')}
              label="Шрифт"
            />
            <Select
              name={'fontSize'}
              options={FONTS_SIZES}
              value={settings.fontSize}
              onChange={handleChangeSettings('fontSize')}
              label="Размер шрифта"
            />
            <Input
              name={'verticalSpacing'}
              type="number"
              value={settings.verticalSpacing}
              onChange={handleChangeSettings('verticalSpacing')}
              label="Вертикальное расстояние"
            />
            <Input
              name={'horizontalSpacing'}
              type="number"
              value={settings.horizontalSpacing}
              onChange={handleChangeSettings('horizontalSpacing')}
              label="Горизонтальное расстояние"
            />
            <Input
              name={'rotationDegree'}
              type="number"
              value={settings.rotationDegree}
              onChange={handleChangeSettings('rotationDegree')}
              label="Угол наклона"
            />
            <Popover
              closeOnClick={false}
              position="top"
              content={
                <RgbaStringColorPicker color={settings.fontColor} onChange={handleColorChange} />
              }
            >
              <div className={styles.settings__color}>
                <label>Цвет текста</label>
                <div style={{ backgroundColor: settings.fontColor }} />
              </div>
            </Popover>
          </div>
          <div className={styles.settings__backgroundPattern}>
            <div
              className={styles.settings__backgroundPattern__text}
              style={{
                fontFamily: settings.fontName,
                fontSize: settings.fontSize,
                rowGap: settings.verticalSpacing && `${settings.verticalSpacing}px`,
                columnGap: settings.horizontalSpacing && `${settings.horizontalSpacing}px`,
                transform: `rotate(${settings.rotationDegree}deg)`,
                color: settings.fontColor,
              }}
            >
              {Array.from({ length: 99 }).map((_, index) => (
                <span key={index}>{textValue}</span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Loader>
  );
};

export default Settings;
