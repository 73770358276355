import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FormikProps, FormikProvider, useFormik } from 'formik';

import { ISignInDto } from 'interfaces/auth.interface';
import { useAuth } from 'contexts/auth.context';
import { signIn, uploadLicense } from 'requests/auth.request';
import { markInvalidFields } from 'utils/form.utils';
import { SIGN_IN_VALIDATION_SCHEMA } from 'constants/auth.constant';
import Loader from 'components/loader/loader.component';
import Input from 'components/input/input.component';
import Button from 'components/button/button.component';
import Icon from 'components/icon/icon.component';
import styles from './sign-in.module.scss';

const SignIn = () => {
  const { t } = useTranslation();
  const { handleSetToken, handleRemoveToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [licenseName, setLicenseName] = useState<string>();
  const formik: FormikProps<ISignInDto> = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: SIGN_IN_VALIDATION_SCHEMA(),
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  useEffect(() => {
    handleRemoveToken();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    formik.validateForm().then(() => {
      if (Object.keys(formik.errors).length > 0) {
        markInvalidFields(formik, 'auth');
      } else {
        setLoading(true);
        signIn(formik.values)
          .then((res) => handleSetToken(res.token))
          .catch(() => toast.error('Произошла ошибка при авторизации'))
          .finally(() => setLoading(false));
      }
    });
  };

  const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    void formik.setFieldValue(name, value, true);
  };

  const handleUploadLicense = ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
    const file = files && files[0];
    if (file) {
      setLoading(true);
      uploadLicense(file)
        .then(() => {
          toast.success('Файл лицензии успешно загружен');
          setLicenseName(file?.name);
        })
        .catch(() => toast.error('Произошла ошибка при загрузке лицензии'))
        .finally(() => setLoading(false));
    }
  };

  return (
    <Loader loading={loading}>
      <div className={styles.auth__container}>
        <div className={styles.auth__title}>
          <img src="/logo192.png" alt="logo" />
          <span>CommonArk</span>
        </div>
        <div className={styles.auth__box}>
          <h2>{t('signIn.title')}</h2>
          <FormikProvider value={formik}>
            <form onSubmit={handleSubmit} noValidate={true}>
              <Input
                formik={formik}
                name="username"
                value={formik.values.username}
                label={t('signIn.username')}
                onChange={handleChange}
              />
              <Input
                formik={formik}
                type="password"
                name="password"
                value={formik.values.password}
                label={t('signIn.password')}
                onChange={handleChange}
              />
              <Button type="submit" primary>
                {t('signIn.submit')}
              </Button>
              <label htmlFor={'licenseUpload'} className={styles.auth__licenseUpload}>
                <input
                  type="file"
                  id="licenseUpload"
                  multiple={false}
                  accept={'.lic'}
                  onChange={handleUploadLicense}
                />
                <Icon name={licenseName ? 'File' : 'Upload'} size={16} />
                <span>{licenseName ?? t('signIn.uploadLicense')}</span>
              </label>
            </form>
          </FormikProvider>
        </div>
      </div>
    </Loader>
  );
};

export default SignIn;
